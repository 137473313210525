import React from 'react'

function Footer() {
  return (
    <div className="w-[100%] h-[80px] flex justify-center items-center bg-white">
    <span className="text-[14px] text-[#0C73B8]">
      &copy; Copyright<b> AAHDC.</b> All Right Reserved.
    </span>
  </div>
  )
}

export default Footer